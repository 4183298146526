//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    dialogClasses: {
      type: String,
      default: '',
    },
    headerClasses: {
      type: String,
      default: 'text-primary text-xl font-bold md:rounded-t-xl p-6 text-center bg-primary-lightest rounded-b-none',
    },
    contentClasses: {
      type: String,
      default: 'p-10',
    },
  },
};
