import { render, staticRenderFns } from "./SaveFavorite.vue?vue&type=template&id=0c9e264b&"
import script from "./SaveFavorite.vue?vue&type=script&lang=js&"
export * from "./SaveFavorite.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SelectList: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/general/SelectList.vue').default,FormFieldInput: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/form/field/Input.vue').default,Modal: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/general/Modal.vue').default})
