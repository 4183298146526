//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from '~/components/Modal';
import SelectList from '~/components/general/SelectList';

import favoritesLists from '~/graphql/queries/dashboard/favoritesLists';
import addProductToList from '~/graphql/mutations/dashboard/addProductToList';
import newFavoritesList from '~/graphql/mutations/dashboard/newFavoritesList';

export default {
  components: {
    Modal,
    SelectList,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  apollo: {
    favoritesLists: {
      query: favoritesLists,
      skip() {
        return !this.$store.state.auth.loggedIn;
      },
      update({favoritesLists}) {
        const transformedList = favoritesLists.map((l) => {
          return {title: l.title, id: l.uuid};
        });

        transformedList.push({title: 'Nieuwe favorietenlijst...', id: 0});

        this.selectedList = transformedList[0];

        return transformedList;
      },
    },
  },
  data: () => ({
    favoritesLists: [],
    selectedList: {
      title: 'Selecteer lijst',
    },
    newListName: null,
  }),
  computed: {
    showNewListInput() {
      return this.selectedList.id === 0;
    },
  },
  watch: {
    visible() {
      if (!this.visible || this.$store.state.auth.loggedIn) {
        return;
      }

      this.$store.commit('favorites/addFavorite', this.product);
      this.$toast.success('Het materiaal is toegevoegd aan je favorietenlijst.');
      this.$emit('event:close');
    },
  },
  methods: {
    async submit() {
      try {
        let listId = this.selectedList.id;

        if (this.showNewListInput) {
          const {data} = await this.$apollo.mutate({
            mutation: newFavoritesList,
            variables: {
              title: this.newListName,
            },
          });

          listId = data.newFavoritesList.uuid;
        }

        await this.$apollo.mutate({
          mutation: addProductToList,
          variables: {
            product_slug: this.product.slug,
            list_uuid: listId,
          },
          refetchQueries: [{
            query: favoritesLists,
          }],
        });

        this.$emit('event:close');
        this.$toast.success('Het materiaal is toegevoegd aan je favorietenlijst.');
        this.product.is_favorite = true;
      } catch (e) {
        console.error(e);
        this.$toast.error('Er is iets mis gegaan tijdens het toevoegen van het product. Probeer het later nog eens.');
      }
    },
    updateSelectedList(filter) {
      this.selectedList = filter.option;
    },
  },
};
