export default {
  props: {
    value: {
      type: [Object, Array, String, Number, Boolean],
      default: '',
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    inputValue(val) {
      this.$emit('input', val);
    },
    value(val) {
      this.inputValue = val;
    },
  },
  methods: {
    cast(val) {
      if (this.inputCast) {
        return this.inputCast(val);
      }

      return typeof val === 'boolean' ? (val ? 1 : 0) : val;
    },
    confirmField() {
      this.inputValue = this.cast(this.inputValue);
    },
  },
};
