//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import input from '~/plugins/mixins/input';

export default {
  mixins: [input],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    labelColor: {
      type: String,
      required: false,
      default: '',
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    fieldValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
