//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    activeOption: {
      type: String,
      default: 'Relevantie',
    },
    activeLength: {
      type: Number,
      default: 0,
    },
    truncate: {
      type: Boolean,
      default: false,
    },
    stylingOptions: {
      type: Object,
      required: false,
      default: () => ({
        textColorLabel: '',
        textColorDropdown: '',
      }),
    },
    resultPage: {
      type: Boolean,
      default: false,
    },
    filterName: {
      type: String,
      default: '',
    },
    showMap: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    openOptions: false,
  }),
  methods: {
    changeOpen() {
      this.openOptions = !this.openOptions;
    },
    setActive(option) {
      this.$emit('update:activeSort', {filter: this.filterName, option});
      this.changeOpen();
    },
    close() {
      this.openOptions = false;
    },
  },
};
